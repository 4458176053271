<template>
  <div v-if="driverDocuments !== ''">




    <!-- <input  type="file" :ref="'test2'" />
    <span @click="foo()" ref="test">this is cool</span> -->
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">Driver documents</h4>
    </div>



    <b-form class="mt-1">
      <b-row>
        <b-col cols="12" md="6" lg="4" class="container-docs" style="height: 100%">
          <b-form-group label="Driver license">
            <input id="imageInterior" type="file" @change="handleimagenLicense()" ref="imagenLicense" />
          </b-form-group>
          <div style="d">
            <b-img
              v-if="isPdfFile(pathlicense) == false && (this.driverDocuments.driver_documents.driver_license != '' && this.driverDocuments.driver_documents.driver_license != null)"
              @click="openImage('driver_license')" class="imgDocVehicle" id="driver_license"
              style="border-radius:5px;height:250px;width: 250px;cursor: pointer;"
              :src="this.driverDocuments.driver_documents.driver_license"></b-img>

            <div v-if="isPdfFile(pathlicense) == true" class="mt-2">
              <div class="row">
                <b-img @click="openPdfNewTab(pathlicense)" class="doc"
                  style="border-radius: 5px;  height: 250px;width: 250px;cursor: pointer"
                  src="@/assets/images/icons/logo_pdf.png"></b-img>
              </div>
              <div class="row">
                <a style="margin: 10px;color: #7b7bfa;" @click="getFile(pathlicense)">Download</a>
              </div>
            </div>

            <div style="width: fit-content; padding: 10px">
              <template v-if="driverDocuments.driver_documents.driver_license_verify_at ===
                undefined ||
                driverDocuments.driver_documents.driver_license_verify_at ===
                null
              ">
                <btn-validation-docs-driver v-if="driverDocuments.driver_documents.driver_license != ''"
                  :driverDocs="driverDocuments.driver_documents" :idVehicle="driverDocuments.id"
                  :idDriver="driverDocuments.id" :valueBtnlicense="btnlicense" :type="licenseType"
                  v-on:deleteImage="deleteImage($event)" />
              </template>
            </div>
          </div>
          <b-form-group v-if="driverDocuments.driver_documents.driver_license != ''" class="mt-1" label="Expiry date">
            <b-form-datepicker v-model="driverDocuments.driver_documents.licenseExpireyDate" locale="en"
              placeholder="0/00/0000" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4" class="container-docs" v-if="this.$hasPermission('driver-sensitive-information-with-bank-inf')
        ">
          <b-form-group label="Proof of insurance">
            <input id="imagenInsurance" type="file" @change="handleimagenInsurance()" ref="imagenInsurance" />
          </b-form-group>
          <div id="divImgDocVehicle">
            <b-img
              v-if="isPdfFile(pathInsurance) == false && (this.driverDocuments.driver_documents.proof_of_insurance != null && this.driverDocuments.driver_documents.proof_of_insurance != '')"
              @click="openImage('proof_of_insurance')" class="imgDocVehicle" id="proof_of_insurance"
              style="border-radius: 5px; height: 250px;width: 250px;cursor: pointer;"
              :src="this.driverDocuments.driver_documents.proof_of_insurance"></b-img>

            <div v-if="isPdfFile(pathInsurance) == true" class="mt-2">
              <div class="row">
                <b-img @click="openPdfNewTab(pathInsurance)" class="doc"
                  style="border-radius: 5px; height: 250px; cursor: pointer"
                  src="@/assets/images/icons/logo_pdf.png"></b-img>
              </div>
              <div class="row">
                <a style="margin: 10px;color: #7b7bfa;" @click="getFile(pathInsurance)">Download</a>
              </div>
            </div>

            <div style="width: fit-content; padding: 10px">
              <template v-if="driverDocuments.driver_documents
                .proof_of_insurance_verify_at === undefined ||
                driverDocuments.driver_documents
                  .proof_of_insurance_verify_at === null
              ">
                <btn-validation-docs-driver :driverDocs="driverDocuments.driver_documents"
                  :idVehicle="driverDocuments.id" :idDriver="driverDocuments.id" :valueBtninsure="btninsure"
                  v-on:deleteImage="deleteImage($event)" type="insurance" />
              </template>
            </div>
          </div>
          <b-form-group v-if="driverDocuments.driver_documents.proof_of_insurance != ''" class="mt-1"
            label="Expiry date">
            <b-form-datepicker v-model="driverDocuments.driver_documents.insuranceExpireyDate" locale="en"
              placeholder="0/00/0000" />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="row">
        <div class="col-12 text-right pr-0">
          <b-button variant="success" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="updateDriver()">
            Update driver documents
          </b-button>
        </div>
      </div>
    </b-form>

    <div>
      <hr class="mt-3 mb-3" style="border: none;height: 3px;color: #333;background-color: rgb(111 89 244); ">
      <div style="display: flex;" class="mb-2">
        <h3>Additional documents</h3>

        <b-button @click="addAdditionalDocument()" class="btn btn-success ml-4" style="float: right;">
          Add new additional document
        </b-button>
      </div>

      <br>
      <b-row md="12">
        <b-col md="4"  v-for="(item, index) in driverDocuments.addiontal_documents" :key="index"
        style="border-style: solid;border-color: rgb(250 248 248); text-align: center;">
          <div style="margin-left: 0px;margin: 30px;">
            <b-img @click="openAdditionalDocument('addit', item)" class="imgDocVehicle" :ref="'addit' + item.id"
              :id="'addit' + item.id" :src="item.src"
              style="border-radius: 5px; height: 250px;width: 250px;cursor: pointer;"></b-img>
            <div>
              <br><br>
              <b>Category:</b><span> {{ item.category.description }}</span>
              <br>
              <b>Has expiration date:</b><span> {{ item.has_expiration_date == "1" ? "Yes" : "No" }}</span>
              <br>
              <template v-if="item.has_expiration_date == '1'">
                <b>Expiry date: </b><span> {{ getDate(item.expiration_date) }}</span>
              </template>

              <br><br>

              <b-button @click="editAdditionalDocument(item)" class="btn btn-warning ml-1 mb-2" style="float: right;">
                Edit
              </b-button>

              <b-button @click="deleteAdditionalDriverDocument(item.id)" class="btn btn-danger mb-2" style="float: right;">
                Delete
              </b-button>

            </div>

          </div>
        </b-col>

      </b-row>


    </div>

    <b-modal no-close-on-backdrop id="bv-modal-upload-additional-document" centered hide-footer size="md">
      <b-form-group label="Image">
        <input @change="handleimagenAdditionalDocument()" id="image-upload-new-additional-document-driver" type="file"
          ref="image-upload-new-additional-document-driver" />
      </b-form-group>

      <b-form-group label="Category">
        <v-select v-model="editCategoryAddionalDocumentsDriver" :options="optionsCategoryAddionalDocumentsDriver"
          label="title" placeholder="Please select" />
      </b-form-group>

      <b-form-group label="Has expiration date">
        <v-select v-model="editHasExpirationDate" id="edit-has-expiration-date" :options="optionsHasExpirationDate"
          label="title" placeholder="Please select" />
      </b-form-group>

      <template v-if="editHasExpirationDate">
        <b-form-group class="mt-1 " label="Expiry date" v-if="editHasExpirationDate.value == '1'">
          <b-form-datepicker v-model="editExpiryDate" id="edit-expiry-date" locale="en" placeholder="0/00/0000" />
        </b-form-group>
      </template>


      <b-button @click="upDateAdditionalDocumentDriver()" class="btn btn-success" style="float: right;">
        Save
      </b-button>

    </b-modal>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BImg,
  BFormDatepicker,
} from "bootstrap-vue";
import btnValidationDocsDriver from "@core/components/buttonsValidationDocsDriver/BtnValidationDocsDriver";
import { mapGetters } from "vuex";
import { getMessageError } from "@core/utils/utils";
import { getMenuYesNo, } from "@core/utils/menus";
import vSelect from "vue-select";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BImg,
    BFormDatepicker,
    btnValidationDocsDriver,
    vSelect,
    moment
  },
  computed: {
    ...mapGetters({
      driverDocuments: "Users/usersData",
    }),
  },
  watch: {
    driverDocuments() {
      this.pathInsurance = this.driverDocuments.driver_documents.proof_of_insurance;
      this.pathlicense = this.driverDocuments.driver_documents.driver_license;

      //Initial load additional documents driver
      if (this.initialLoadAdditionalDocument == true) {
        this.driverDocuments.addiontal_documents.forEach(
          element => {
            this.setImageAdditionalDocumentDriver(element, 'addit' + element.id);
          }
        );
        this.initialLoadAdditionalDocument = false;
      }

      this.setImages(
        this.driverDocuments.driver_documents.driver_license,
        this.driverDocuments.driver_documents.proof_of_insurance
      );
    },
  },
  data() {
    return {
      btnlicense: true,
      btninsure: true,

      imagenLicense: "",
      imagenInsurance: "",

      imageLicense: "",
      imageInsurance: "",

      licenseType: "license",
      InsuranceType: "insurance",

      pathlicense: '',
      pathInsurance: '',

      licenseVerify: false,
      InsuranceVerify: false,
      countUploadedImages: 0,
      optionsHasExpirationDate: [],
      optionsCategoryAddionalDocumentsDriver: [],
      editIdAddionalDocumentsDriver: null,
      editImageAdditionalDocument: null,
      editHasExpirationDate: null,
      editExpiryDate: null,
      editCategoryAddionalDocumentsDriver: null,
      initialLoadAdditionalDocument: true,
    };
  },
  methods: {
    handleimagenLicense() {
      this.imageLicense = this.$refs.imagenLicense.files[0];
      this.compressImage(this.imageLicense, "imageLicense");
    },
    handleimagenInsurance() {
      this.imageInsurance = this.$refs.imagenInsurance.files[0];
      this.compressImage(this.imageInsurance, "imageInsurance");
    },
    handleimagenAdditionalDocument() {
      this.editImageAdditionalDocument = this.$refs['image-upload-new-additional-document-driver'].files[0];
      console.log(this.editImageAdditionalDocument);
      this.compressImage(this.editImageAdditionalDocument, "additionalDocumentDriver");
    },
    compressImage(url, type) {
      const WIDTH = 800;
      let image_file = url;

      let reader = new FileReader();

      reader.readAsDataURL(image_file);

      reader.onload = (event) => {
        let image_url = event.target.result;
        let image = document.createElement("img");
        image.src = image_url;
        image.onload = (e) => {
          let canvas = document.createElement("canvas");
          let ratio = WIDTH / image.width;
          canvas.width = WIDTH;
          canvas.height = image.height * ratio;

          let context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, canvas.width, canvas.height);

          let new_image_url = canvas.toDataURL("image/jpeg", 10);

          let image_file = this.urlToFile(new_image_url);
          if (type == "imageLicense") {
            this.imageLicense = image_file;
          }
          if (type == "imageInsurance") {
            this.imageInsurance = image_file;
          }
          if (type == "additionalDocumentDriver") {
            this.editImageAdditionalDocument = image_file;
          }
        };
      };
    },
    urlToFile(url) {
      let arr = url.split(",");
      // console.log(arr)
      let mime = arr[0].match(/:(.*?);/)[1];
      let data = arr[1];

      let dataStr = atob(data);
      let n = dataStr.length;
      let dataArr = new Uint8Array(n);

      while (n--) {
        dataArr[n] = dataStr.charCodeAt(n);
      }

      let file = new File([dataArr], "File.jpg", { type: mime });

      return file;
    },
    updateDriver(e) {
      // e.preventDefault()
      let formData = new FormData();
      formData.append(
        "licenseExpireyDate",
        this.driverDocuments.driver_documents.licenseExpireyDate
      );
      formData.append(
        "insuranceExpireyDate",
        this.driverDocuments.driver_documents.insuranceExpireyDate
      );

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
        timer: 1000,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });

      this.$http
        .post(
          `auth/driver/${this.driverDocuments.id}/document/update`,
          formData,
          config
        )
        .then((res) => {
          this.$swal({
            title: res.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });

          this.uploadDriverLicenseImage();
          this.uploadProofInsuranceImage();
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    deleteAdditionalDriverDocument(idAddionalDocumentsDriver) {
      let formData = new FormData();

      formData.append("idAddionalDocumentsDriver", idAddionalDocumentsDriver);


      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });

      this.$http
        .post(
          `admin/panel/delete_additional_dcuments_driver`,
          formData,
        )
        .then((res) => {
          this.$swal.close();
          this.driverDocuments.addiontal_documents = this.driverDocuments.addiontal_documents.filter(x => x.id != idAddionalDocumentsDriver);
          console.log(idAddionalDocumentsDriver);
          console.log(this.driverDocuments.addiontal_documents)
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },

    uploadDriverLicenseImage() {
      let formData = new FormData();
      formData.append("driver_license", this.imageLicense);
      this.uploadImage(formData, "driver_license");
    },
    uploadProofInsuranceImage() {
      let formData = new FormData();
      formData.append("proof_of_insurance", this.imageInsurance);
      this.uploadImage(formData, "proof_of_insurance");
    },
    upDateAdditionalDocumentDriver() {
      let formData = new FormData();
      formData.append("idAddionalDocumentsDriver", this.editIdAddionalDocumentsDriver);
      formData.append("driver_id", this.driverDocuments.id);
      formData.append("has_expiration_date", this.editHasExpirationDate.value);

      if ((this.editExpiryDate == null || this.editExpiryDate == "") == true && this.editHasExpirationDate.value == "1") {
        this.$swal({
          title: "Expiration date must have a value",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;

      } else {
        if (this.editHasExpirationDate.value == "1") {
          formData.append("expiration_date", this.editExpiryDate);
        }

      }



      formData.append("additional_document_driver", this.editImageAdditionalDocument);
      formData.append("category_addtional_document_id", this.editCategoryAddionalDocumentsDriver.value);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      this.$swal({
        title: "Please, wait processing data...",
        didOpen: () => {
          this.$swal.showLoading();
        },
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });

      this.$http
        .post(
          `admin/panel/update_additional_documents_driver`,
          formData,
          config
        )
        .then((res) => {
          this.$swal.close();
          this.$bvModal.hide("bv-modal-upload-additional-document");
          let dataDocument = res.data.data
          if (this.editIdAddionalDocumentsDriver == null) {
            this.driverDocuments.addiontal_documents.push(dataDocument);
          } else {
            //let infoDocument= this.driverDocuments.addiontal_documents.find(x=>x.id==this.editIdAddionalDocumentsDriver);
            let objIndex = this.driverDocuments.addiontal_documents.findIndex(x => x.id == this.editIdAddionalDocumentsDriver);
            this.driverDocuments.addiontal_documents[objIndex] = dataDocument;
            // infoDocument=dataDocument


            console.log(this.driverDocuments.addiontal_documents)
            //console.log(infoDocument)
            console.log(dataDocument)
          }

          this.setImageAdditionalDocumentDriver(dataDocument, 'addit' + dataDocument.id);
          this.clearFormEditValuesAdditionalDocumentDriver();
        })
        .catch((error) => {
          this.countUploadedImages = 0;
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    clearFormEditValuesAdditionalDocumentDriver() {
      this.editIdAddionalDocumentsDriver = null;
      if (this.optionsCategoryAddionalDocumentsDriver.length > 0) {
        this.editCategoryAddionalDocumentsDriver = this.optionsCategoryAddionalDocumentsDriver[0];
      }
      if (this.optionsHasExpirationDate.length > 0) {
        this.editHasExpirationDate = this.optionsHasExpirationDate[0];
      }

      this.editExpiryDate = null;
      this.editImageAdditionalDocument = null;
      //document.getElementById("image-upload-new-additional-document-driver").value = "";
    },
    uploadImage(formData, imageType) {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      this.$swal({
        title: "Please, wait processing data...",
        didOpen: () => {
          this.$swal.showLoading();
        },
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });

      this.$http
        .post(
          `auth/driver/${this.driverDocuments.id}/document/upload_driver_documents_files`,
          formData,
          config
        )
        .then((res) => {
          this.countUploadedImages++;
          let documentData = JSON.parse(res.data.data);

          let timestamp = new Date().getTime();
          if (this.driverDocuments.driver_documents.driver_license != null && this.driverDocuments.driver_documents.driver_license != '') {
            this.driverDocuments.driver_documents.driver_license = timestamp;
          }
          if (this.driverDocuments.driver_documents.proof_of_insurance != null && this.driverDocuments.driver_documents.proof_of_insurance != '') {
            this.driverDocuments.driver_documents.proof_of_insurance = timestamp;
          }


          this.pathlicense = documentData.driver_license;
          this.pathInsurance = documentData.proof_of_insurance;

          if (imageType == "driver_license") {
            this.setImages(documentData.driver_license, null);
          }
          if (imageType == "proof_of_insurance") {
            this.setImages(null, documentData.proof_of_insurance);
          }

          if (this.countUploadedImages == 2) {
            this.$swal({
              title: "Data updated",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.countUploadedImages = 0;
          }
        })
        .catch((error) => {
          this.countUploadedImages = 0;
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    deleteImage(type) {
      if (type === "license") {
        this.driverDocuments.driver_documents.driver_license = "";
      }
      if (type === "insurance") {
        this.driverDocuments.driver_documents.proof_of_insurance = "";
      }
    },
    async getImage(filePath) {
      let res = "";
      if (filePath == "" || filePath == null) {
        return "";
      }
      await this.$http
        .get(`private-files/${filePath}`)
        .then((response) => {
          res = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return res;
    },
    async setImages(licenseExpireyDate, insuranceExpireyDate) {
      if (insuranceExpireyDate != null) {
        this.driverDocuments.driver_documents.proof_of_insurance =
          await this.getImage(insuranceExpireyDate);
      }
      if (licenseExpireyDate != null) {
        this.driverDocuments.driver_documents.driver_license =
          await this.getImage(licenseExpireyDate);
      }
    },
    async setImageAdditionalDocumentDriver(additionalDocument, idElement) {
      let res = null;
      if (additionalDocument.file_path != null) {
        res = await this.getImage(additionalDocument.file_path);
      }
      if (this.isPdfFile(additionalDocument.file_path)) {
        res = '/img/logo_pdf.ef815cb7.png';
      }
      additionalDocument['src'] = res;
      var image = document.getElementById(idElement);
      if (image != null) {
        image.src = res;
      }
      console.log(this.driverDocuments.addiontal_documents)
    },
    openImage(id) {
      var win = window.open();
      let base64URL = document.getElementById(id).src;
      win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    },
    openAdditionalDocument(tag, item) {

      if (this.isPdfFile(item.file_path) == true) {
        this.openPdfNewTab(item.file_path);
      } else {
        let win = window.open();
        let base64URL = document.getElementById(tag + item.id).src;
        win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
      }

    },
    isPdfFile(file) {
      if (file == null)
        return false;
      file = file.split(".").pop();
      const acceptedImageTypes = ["pdf"];
      let res = file && acceptedImageTypes.includes(file);
      this.fileExtesion = file;
      return res;
    },
    async getFile(filePath) {
      let res = "";
      await this.$http
        .get(`download_file_private_storage/${filePath}`, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        })
        .then((response) => {
          res = response.data;

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
    async openPdfNewTab(filePath) {
      let res = "";
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      await this.$http
        .get(`download_file_private_storage/${filePath}`, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/pdf",
          },
        })
        .then((response) => {
          var blob = new window.Blob([response.data], {
            type: "application/pdf",
          });
          var url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          var win = window.open();
          win.document.write(
            '<iframe src="' +
            url +
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
          );
          this.$swal.close();
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
      return res;
    },
    foo() {
      alert(this.$refs['addit'])
    },
    async loadMenus() {
      this.optionsHasExpirationDate = getMenuYesNo();
      this.getCategoriesAdditionalDocuments();
      if (this.optionsCategoryAddionalDocumentsDriver.length > 0) {
        this.editCategoryAddionalDocumentsDriver = this.optionsCategoryAddionalDocumentsDriver[0];
      }
      if (this.optionsHasExpirationDate.length > 0) {
        this.editHasExpirationDate = this.optionsHasExpirationDate[0];
      }

    },
    loadCategories(list) {

      list.forEach(element => {
        this.optionsCategoryAddionalDocumentsDriver.push(
          {
            value: element.id,
            title: element.description,
          }
        )
      });
    },
    async getCategoriesAdditionalDocuments() {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .get(`admin/panel/get_category_addional_documents_driver`)
        .then((response) => {
          this.loadCategories(response.data.data);

          //this.calculatePrice();
          this.$swal.close();
        })
        .catch((error) => {
          let message = getMessageError(error);
          this.$swal({
            title: message,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    addAdditionalDocument() {
      this.$bvModal.show("bv-modal-upload-additional-document");
      this.clearFormEditValuesAdditionalDocumentDriver();
    },
    editAdditionalDocument(item) {
      this.$bvModal.show("bv-modal-upload-additional-document");
      this.editIdAddionalDocumentsDriver = item.id;
      this.editCategoryAddionalDocumentsDriver = this.optionsCategoryAddionalDocumentsDriver.find(x => x.value == item.category_addtional_document_id);
      this.editHasExpirationDate = this.optionsHasExpirationDate.find(x => x.value == item.has_expiration_date);
      this.editExpiryDate = item.expiration_date;
    },
    getDate(data) {

      let res = ''
      try {
        res = moment(data).format('MM-DD-YYYY');
        if (res == "Invalid date") {
          res = "";
        }
      } catch (error) {

      }
      return res;
    }
  },
  mounted() {
    this.loadMenus();
  },
  created() {
    // if( this.driverDocuments)
    // {
    //   this.driverDocuments.addiontal_documents.forEach(element => {
    //         this.setImageDocument(element);
    // }); 
    // }

  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.container-docs {
  position: relative;

  .imgDocVehicle {
    min-width: 188px;
  }

  .btnAllDocuments {
    position: absolute;
    height: 39px;
    width: 38px;
    padding: 7px;
    border-radius: 18px;
    left: 260px;
  }

  .btnCheck {
    @extend .btnAllDocuments;
    top: 176px;
  }

  .btnCancel {
    @extend .btnAllDocuments;
    top: 135px;
  }

  .btnDownload {
    @extend .btnAllDocuments;
    //background-color: #07ff00 !important;
    top: 92px;
  }
}
</style>
